import React from "react"
import { graphql } from "gatsby"
import { PageLayoutHero } from "../components"
import { SEO } from "../utils"


export default ({ data }) => {
  return (
    <PageLayoutHero data={data}>
      <SEO title="Home" />
    </PageLayoutHero>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        unemployed
        firstName
        lastName
        occupation
      }
    }
    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            tags
            author
            link
            repo
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    articles: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/articles/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            description
            tags
            date(formatString: "DD MMMM, YYYY")
            link
          }
          fields {
            slug
          }
          excerpt
          html
        }
      }
    }
  }
`
